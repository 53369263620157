
import DefaultHeader from '../components/layout/DefaultHeader.vue'
import SolutionsOverview from '../components/content/SolutionsOverview.vue'
import WhyChoose from '../components/content/WhyChoose.vue'

export default {
  components: {
    DefaultHeader,
    SolutionsOverview,
    WhyChoose,
  },

  layout: 'primary',

  async asyncData({ $content }) {
    const articles = await $content('blog')
      .only(['title', 'description', 'img', 'slug', 'createdAt'])
      .sortBy('createdAt', 'desc')
      .limit(3)
      .fetch()

    return {
      articles,
    }
  },

  head: {
    title: 'Taxpace - Automate your digital tax compliance',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        hid: 'description',
        name: 'description',
        content:
          'Automate your digital tax obligations to focus on growing your digital business.',
      },
    ],
  },

  methods: {
    formatDate(date) {
      // format the date to be displayed in a readable format
      return new Date(date).toLocaleDateString('en', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
  },
}
